.select-dropdown {
  display: block;
  height: 40px;
  min-width: 80px;
}

.select-dropdown > button {
  height: 40px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.select-dropdown > button:focus,
.select-dropdown > button:focus:hover {
  background-color: #fff;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
}

.select-dropdown > button:active:hover {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: none;
}

.select-dropdown > button:hover {
  background-color: #fff;
  border-color: #ccc;
}

@-moz-document url-prefix() {
  .select-dropdown > button > span.caret {
    float: right;
    color: #555555;
  }
}

.show > .btn-primary.dropdown-toggle:active {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 12px;
  color: #555;
}

.select-dropdown button.dropdown-toggle,
.select-dropdown button.dropdown-toggle:focus {
  text-align: left;
  font-weight: normal;
  height: 40px;
  width: 100%;
}

.select-dropdown-toggle-is-open {
  background-color: #e6e6e6 !important;
  color: #555555 !important;
  border-color: #adadad !important;
}

.select-dropdown-toggle-is-open:hover {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
}

.selected-dropdown-title {
  display: inline-block;
  color: #555555;
  width: 85%;
  height: 100%;
  padding-top: 5px;
  overflow-y: visible;
  overflow-x: hidden;
}

.selected-dropdown-title-placeholder {
  color: #a9a9a9;
}

.selected-dropdown-title-placeholder-open:hover {
  color: #a9a9a9;
}

.select-dropdown-menu {
  margin-top: 0px;
  max-height: 350px;
  overflow-y: auto;
}

.has-error .select-dropdown > button,
.has-error .select-dropdown > button:hover {
  border-color: #a94442;
  box-shadow: none;
}
.has-error .select-dropdown > button:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
}
