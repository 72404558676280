.detalj-ileggelse-kommentarerer {
  margin-top: 17px;
}

.detalj-ileggelse-kommentarerer h5 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 9px;
}

.detalj-ileggelse-kommentarerer p {
  font-size: 16px;
}

@media print {
  .detalj-ileggelse-kommentarerer {
    margin-top: 17px;
    padding: 0;
  }
}
