.modalGeneric label {
  height: 15px;
  font-family: Lato;
  font-size: 12px;
  text-align: left;
  color: #606060;
  padding-top: 15px;
  padding-bottom: 10px;
}

.modalGeneric input {
  max-width: 100%;
  /* height: 40px; */
  border-radius: 6px;
  border: solid 1px #e5e5e5;
}
