.bym-table > thead > tr > th {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 10px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
  border: none;
}

.bym-table > thead > tr > th:first-child {
  padding: 20px 10px 20px 20px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
}

.bym-table > tbody > tr > td {
  font-size: 14px;
  font-weight: normal;
  padding: 20px 10px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
}

.bym-table > tbody > tr > td:first-child {
  padding: 20px 10px 20px 20px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
}

/* .bym-table > tbody > tr:hover {
  opacity: 0.87;
  background-color: #f5f5f5;
} */

.bym-table.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
  color: #4d4d4d;
}

.bym-table.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f3f3f3;
  color: #4d4d4d;
}

.bym-table.table-striped > thead > tr {
  background-color: #f3f3f3;
  color: #4d4d4d;
  border-top: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
}

.bym-table.table-double-row-striped.table-striped tbody > tr:nth-of-type(4n),
.bym-table.table-double-row-striped.table-striped tbody > tr:nth-of-type(4n-1) {
  background-color: #f3f3f3;
}

.bym-table.table-double-row-striped.table-striped tbody > tr:nth-of-type(4n-2),
.bym-table.table-double-row-striped.table-striped tbody > tr:nth-of-type(4n-3) {
  background-color: #ffffff;
}

.bym-table.table-double-row-striped.table-striped
  tbody
  > tr:nth-of-type(2n)
  > td {
  border-top: none;
  padding-top: 0;
}

.bym-table.table-double-row-striped.table-striped tbody > tr > td:first-child {
  padding: 20px 10px 20px 20px;
}

.table-wrapper-horizontal-scroll {
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.table-wrapper-horizontal-scroll-white-space-normal {
  width: 100%;
  display: block;
  overflow-x: auto;
}
