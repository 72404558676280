.table-striped > thead > tr {
  background-color: #f3f3f3;
  color: #4d4d4d;
  border-top: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
  color: #4d4d4d;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f3f3f3;
  color: #4d4d4d;
}
