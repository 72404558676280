@media screen {
  .checkbox-container input[type="checkbox"] {
    position: absolute;
    left: -1000px;
  }

  .checkbox-container input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px 0 0;
    background: url("../../../images/check-box.svg") no-repeat 0 0;

    /* The following is used to adjust the position of 
    the check boxes on the text baseline */

    vertical-align: middle;
    position: relative;
    /* bottom: 2px; */
  }

  .checkbox-container input[type="checkbox"]:checked + label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px 0 0;
    background: url("../../../images/check-box-checked.svg") no-repeat 0 0;

    /* The following is used to adjust the position of 
    the check boxes on the text baseline */

    vertical-align: middle;
    position: relative;
    /* bottom: 2px; */
  }

  .checkbox-container input[type="checkbox"]:focus + label:before {
    outline: 1px dotted black;
  }

  .checkbox-container div#checkboxTitle {
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
    height: 3px;
    color: #606060;
  }
}
