.ine-statuskoder-menue {
  background-color: #f3f3f3;
  min-height: 54px;
  padding-left: 50px;
  vertical-align: middle;
  /* line-height: 16px; */
}

.ine-statuskoder-menue a {
  padding-top: 15px;
  border-radius: 0px !important;
  color: #4d4d4d;
  font-weight: 700;
}

.ine-statuskoder-menue a.active {
  color: #000 !important;
}
