.dropdownsearchable .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

#placeholder {
  font-style: italic;
  letter-spacing: 0.3px;
  text-align: left;
  color: #717171;
}

.dropdownsearchable .btn-default,
.dropdownsearchable .toggleButtn {
  color: #555555;
}

.dropdownsearchable .open > .dropdown-toggle.btn-default,
.dropdownsearchable .open > .dropdown-toggle.btn-default:focus {
  background-color: white;
}

.multi-filter-dropdown {
  display: block;
}

input::-ms-clear {
  display: none;
}

.multi-filter-dropdown button.dropdown-toggle,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover,
.open > .dropdown-toggle.btn-default,
.multi-filter-dropdown button.dropdown-toggle:focus {
  width: 100%;
}

.multi-filter-dropdown > button {
  background-color: #ffffff;
  width: 100%;
  box-shadow: #ffffff;
  padding: 6px 12px;
  max-width: 480px;
  border-radius: 6px;
  border: solid 1px #ccc;
  height: 40px;
  overflow-x: hidden;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.multi-filter-dropdown > button.toggleButtn {
  text-align: left;
  font-weight: normal;
  color: #555555;
}

.dropdown-list-searchable-toggle-placeholder {
  color: #a9a9a9 !important;
}

.dropdown-list-searchable-toggle-placeholder-open {
  color: #555555 !important;
}

.dropdown-list-searchable-toggle-placeholder-open:hover {
  color: #a9a9a9 !important;
}

.multi-filter-dropdown .dropdown-toggle:hover {
  background-color: #ffffff;
}

.multi-filter-dropdown .dropdown-toggle::after {
  margin-top: 7px;
}

.multi-filter-dropdown > button:hover {
  background-color: #ffffff;
  border: solid 1px #cccccc;
}

.multi-filter-dropdown > button:focus,
.multi-filter-dropdown > button:active {
  background-color: #ffffff;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
}

.multi-filter-dropdown > button:active:focus {
  background-color: #ffffff;
  box-shadow: none;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: none;
}

.multi-filter-dropdown-list {
  max-height: 350px;
  margin-top: 2px !important;
  overflow-y: auto;
  z-index: 2000;
  padding: 15px;
  min-width: 308px;
}

.multi-filter-dropdown-list .selectedItemBgColor {
  background-color: #f1f1f1;
  margin-top: 2px;
}

.multi-filter-dropdown-list .focusedItem {
  border-radius: 13px;
}

div.multi-filter-dropdown-list > li {
  padding: 4px;
  font-size: 16px;
}

div.multi-filter-dropdown-list > li > div > label > span {
  font-size: 16px;
  margin-left: 5px;
  margin-top: -5px;
  vertical-align: text-bottom;
  letter-spacing: 0.3px;
  cursor: pointer;
}

div.multi-filter-dropdown-list > li:hover {
  background-color: #f3f3f3;
  cursor: pointer;
  display: block;
}

div.multi-filter-dropdown-list > li > div:hover {
  cursor: pointer;
}

.multi-filter-dropdown-list .btnClose {
  margin: 10px 20px 15px 15px;
  padding: 5px;
  width: 220px;
  height: 40px;
}
.menuItem {
  color: #555555;
}
.multi-filter-dropdown-list .menuItem .name {
  display: inline-block;
  text-align: left;
  padding-left: 5px;
  font-size: 14px;
}

.multi-filter-dropdown-list .menuItem .description {
  padding-left: 5px;
  text-align: left;
  font-size: 12px;
}

ul#meldingstyperMultiFilters {
  min-width: 500px;
  max-width: 524;
}

.multi-filter-dropdown-list .form-group {
  margin: 0px;
}

.has-error .multi-filter-dropdown > button {
  border-color: #a94442;
}
