.miljogebyr-content-wrapper {
  padding-bottom: 20px;
}

.miljogebyr-tab-content-wrapper {
  margin-top: 11px;
}

.detalj-miljogebyr-section-margin {
  margin: 20px 50px;
}

.detalj-miljogebyr-header-wrapper {
  margin: 0 50px;
  border-bottom: 1px solid #f5f5f5;
}

.detalj-miljogebyr-header-wrapper h2 {
  font-size: 19px;
  font-weight: bold;
}

.detalj-miljogebyr-table-wrapper {
  margin: 0 50px;
}

.detalj-miljogebyr-historikk-section-margin .nav {
  padding-left: 50px;
}

.detalj-miljogebyr-historikk-section-margin .nav > li {
  font-size: 19px;
  font-weight: bold;
}

.detalj-miljogebyr-historikk-section-margin .tab-content {
  padding: 20px 50px;
}

.miljogebyrDetailPutModal {
  width: 70%;
  max-width: none;
}

@media print {
  .detalj-miljogebyr-header-wrapper {
    margin: 0 0;
    border-bottom: 1px solid #f5f5f5;
  }

  .detalj-miljogebyr-table-wrapper {
    margin: 0 0;
  }

  .detalj-miljogebyr-section-margin {
    margin: 20px 0;
  }
}
