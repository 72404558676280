.ettersendelse-list-page-total-ettersendelser {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4d4d4d;
  font-weight: bold;
}

.ettersendelse-list-page-content-container {
  width: 100%;
  display: block;
  overflow-x: auto;
}

/* ettersendelser specific */

.bym-table.bym-ettersendelser-table.table-striped tbody > tr:nth-of-type(4n),
.bym-table.bym-ettersendelser-table.table-striped tbody > tr:nth-of-type(4n-1) {
  background-color: #f3f3f3;
}

.bym-table.bym-ettersendelser-table.table-striped tbody > tr:nth-of-type(4n-2),
.bym-table.bym-ettersendelser-table.table-striped tbody > tr:nth-of-type(4n-3) {
  background-color: #ffffff;
}

.bym-table.bym-ettersendelser-table.table-striped tbody > tr:nth-child(2n) > td,
.bym-table.bym-ettersendelser-table.table-striped
  tbody
  > tr:nth-child(2n)
  > td:first-child,
.bym-table.bym-ettersendelser-table.table-striped
  tbody
  > tr:nth-child(2n)
  > td:nth-child(2) {
  border-top: 0;
  padding-top: 0;
}

.bym-table.bym-ettersendelser-table.table-striped tbody > tr > td:first-child,
.bym-table.bym-ettersendelser-table.table-striped thead > tr > th:first-child {
  padding: 20px 10px 20px 20px;
  width: 16px;
}

.bym-table.bym-ettersendelser-table.table-striped tbody > tr > td,
.bym-table.bym-ettersendelser-table.table-striped thead > tr > th {
  padding: 20px 10px;
}
