.header-cell {
  cursor: pointer;
}

.header-cell > .hc-sort {
  margin-left: 12px;
}

.header-cell > .hc-sort > img {
  width: 13px;
  height: 20px;
  object-fit: contain;
  display: none;
}

.header-cell > .hc-sort > img.hc-sort-asc {
  display: none;
}

.header-cell > .hc-sort-normal > .hc-img-normal {
  display: inline;
}

.header-cell > .hc-sort-asc > .hc-img-asc {
  display: inline;
}

.header-cell > .hc-sort-desc > .hc-img-desc {
  display: inline;
}
