.collapsible {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 10px;
}

.collapsible .titleRow {
  font-weight: bold;
  min-height: 55px;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 15px 0px 10px 15px;
}
.collapsible .collapsibleCheckbox {
  float: left;
}

.collapsible .title {
  cursor: pointer;
}

.collapsible .titleContent {
  padding-left: 15px;
  padding-right: 15px;
}
.collapsible table tbody > tr > td > a.btn-link {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  text-align: left;
  color: #0071bb;
  text-decoration: underline;
}
