.ileggelse-list-page-total-ileggelser {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4d4d4d;
  font-weight: bold;
}

.ileggelse-list-page-content-container {
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
