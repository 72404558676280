@media screen and (min-width: 768px) {
  .SearchKlageForm {
    margin-top: 0;
    padding: 10px 50px;
    height: 160px;
    background-color: #f3f3f3;
    border: solid 1px #e9e9e9;
  }
}

@media screen and (max-width: 767px) {
  .SearchKlageForm {
    padding: 10px 50px;
    background-color: #f3f3f3;
    border: solid 1px #e9e9e9;
  }
}

.klageContainer {
  padding: 10px 50px;
}

.saksnumerAndTilteRow .col-md-6 {
  float: left;
  padding-left: 65px;
  margin-top: 20px;
}

.klageIleggelserTabs .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding: 20px;
  font-size: 16px;
}

.klageIleggelserTabs .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  padding: 20px;
  font-weight: 700;
}

.klageIleggelserTabs button#addIleggelser {
  float: right;
}

.klageIleggelseDetaljer #LinkToIleggelseDetailjer {
  float: right;
  bottom: -15px;
}

.KlageOgIleggelseTabber .grid-table {
  min-width: 0px;
}

.KlageOgIleggelseTabber .grid-table tr {
  background-color: white;
}

.KlageOgIleggelseTabber .grid-table tr > td {
  padding-left: 0px;
}
.KlageOgIleggelseTabber .row {
  margin-right: 0;
}

.klagevedleggDiv .overskrift {
  border: none !important;
  margin-top: 50px;
  padding-bottom: 10px;
  padding-left: 0;
}

.klagevedleggDiv {
  margin-left: 0px;
}

.vedleggerList {
  margin-top: 5px;
  padding-left: 20px;
}

.vedleggerList table {
  width: 100%;
}

.vedleggerList table > tbody > tr {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.vedleggerList table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.klageFileDiv {
  min-width: 200px;
  padding-left: 10px;
}
.skiltIcon {
  cursor: text;
  width: 30px;
}
.klageFileDiv .klageFileName {
  color: #0071bb;
  cursor: text;
  font-size: 16px;
  font-weight: normal;
}

.klageFileDiv .klageFileDate {
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
}

.klagevedleggDownload {
  float: right;
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
}

table#klageInfoDetailTable tr > td {
  height: 80px;
  padding-left: 0px;
}

.klageinfo-row {
  margin-top: 30px;
}

.klageinfo-label {
  font-size: 12px;
  font-weight: bold;
}

.klageinfo-text {
  font-size: 16px;
  font-weight: normal;
  word-wrap: break-word;
}

.klageIleggelseDetaljer #GenerictTabsDiv-tab-2 {
  width: 200px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
}

.klageIleggelseDetaljer a#GenerictTabsDiv-tab-2 span.tabTitle {
  color: #0071bb;
}

.klageIleggelseDetaljer .one-colored-grid-table td:first-child {
  padding-left: 0;
}

.klageIleggelseDetaljer .one-colored-grid-table td:last-child {
  padding-right: 0;
}

.table.vedtakerTable thead > tr {
  background-color: #f1f1f1;
}

.table.vedtakerTable thead > tr > td:first-child {
  padding: 20px 10px 20px 20px;
}

.table.vedtakerTable tbody > tr > td:first-child {
  padding: 20px 10px 20px 20px;
}

.klageDetailTabs .overskrift {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #ddd;
}
