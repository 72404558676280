.navbar-bym.navbar {
  background-color: #d8d8d8;
  min-height: 65px;
  border-bottom: solid 3px #fea90a;
  margin: 0px;
  padding: 0px;
}

.byvaap-cmyk {
  width: 110px;
  height: 59px;
  object-fit: cover;
  margin-right: 11px;
  margin-left: 16px;
}

.navbar-bym .brand-post-text {
  display: inline-block;
  vertical-align: top;
  color: #000000;
  padding-top: 17px;
}

.navbar-bym .navbar-brand {
  color: #7b7a7a;
  font-size: 20px;
  font-weight: 500;
  line-height: inherit;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-bym .navbar-brand a {
  display: inline-block;
  height: 100%;
}

@media only screen and (min-width: 975px) {
  .navbar-bym .navbar-nav {
    flex-flow: wrap;
  }

  #meny-bruker-profil {
    padding: 15px 15px 0px 15px;
    min-width: 75px;
  }
}

@media only screen and (max-width: 974px) {
  .navbar-bym.navbar {
    padding-right: 16px;
  }

  .navbar-bym .navbar-nav {
    flex-flow: none;
  }

  .navbar-bym .navbar-nav a.nav-link,
  .navbar-bym .navbar-nav a.nav-link:hover {
    height: 40px;
    border-left: none;
    padding-top: 12px;
  }

  #meny-bruker-profil {
    padding-top: 0px;
  }

  #meny-bruker-profil.dropdown-toggle::after {
    float: none;
  }
}

.navbar-bym a {
  text-decoration: none;
}

.navbar-bym a.nav-link,
.navbar-bym .navbar-link a.nav-link:hover,
.navbar-bym .navbar-link a.nav-link.active:hover {
  color: #4d4d4d !important;
  height: 60px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 16px;
  padding: 22px 15px 22px 15px;
  border-left: 1px solid #4d4c4d;
  cursor: pointer;
}

.navbar-bym a.nav-link.active,
.navbar-bym a.nav-link.active:hover {
  font-weight: 700;
  background-color: #e7e7e7;
}

.navbar-bym .navbar-nav > .active > a,
.navbar-bym .navbar-nav > .active > a:hover,
.navbar-bym .navbar-nav > .active > a:focus {
  color: #4d4d4d;
  background-color: transparent;
}
.navbar-bym .navbar-nav > .disabled > a,
.navbar-bym .navbar-nav > .disabled > a:hover,
.navbar-bym .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}

.nav-item.dropdown {
  padding: 0 !important;
}
