.tabTitle {
  color: #4d4d4d;
}

nav.noboarderStyle > a.nav-item.nav-link,
nav.noboarderStyle > a.nav-item.nav-link:hover {
  /* color: #4d4d4d; */
  border-color: transparent;
  text-align: center;
}

nav.noboarderStyle > a.nav-item.nav-link.active,
nav.noboarderStyle > a.nav-item.nav-link.active:hover {
  /* border-color: transparent; */
  border-bottom: 2px solid #000;
}
