.table > thead > tr > td {
  padding: 20px 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
  /* background-color: #f3f3f3; */
}
.table > thead > tr > th {
  padding: 20px 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
  /* background-color: #f3f3f3; */
}
.table > tbody > tr > td {
  padding: 20px 10px;
}
