.gallery-image-meta-data-wrapper {
  border: solid 1px #f3f3f3;
  width: 200px;
}

.gallery-file-name-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.gallery-checkbox-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.gallery-checkbox-wrapper label {
  font-size: 16px;
}

.image-buttons-container {
  display: none;
}

.image-buttons-container:hover {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.gallery-image-wrapper:hover + .image-buttons-container {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}
