.bym-table.table-striped > tbody > tr .editable-field-for-table {
  height: 40px;
  border-radius: 6px;
  border-style: dashed;
  border-width: 1px;
  padding: 9px 12px 6px 0px;
}

.bym-table.table-striped
  > tbody
  > tr:nth-of-type(odd)
  .editable-field-for-table {
  border-color: #fff;
}

.bym-table.table-striped
  > tbody
  > tr:nth-of-type(odd)
  .editable-field-for-table:hover {
  border-color: #ccc;
}

.bym-table.table-striped
  > tbody
  > tr:nth-of-type(even)
  .editable-field-for-table {
  border-color: #f3f3f3;
}

.bym-table.table-striped
  > tbody
  > tr:nth-of-type(even)
  .editable-field-for-table:hover {
  border-color: #ccc;
}

.editable-field-jira .form-control.form-control-for-table {
  font-size: 14px;
}
