@media screen {
  .print-container {
    padding: 0 50px;
  }
}

.print-container h1 {
  font-size: 24px;
}

.print-container .query-parameter-section {
  margin-bottom: 50px;
}

.print-container .query-parameter-section div {
  font-weight: normal;
  font-size: 16px;
}

.print-container .number-of-table-rows-container {
  margin-top: 50px;
  margin-bottom: 20px;
}

.print-container .number-of-table-rows-container .number-of-table-rows {
  font-weight: bold;
}

.print-container table {
  font-size: 10px;
  width: 100%;
}

.print-container table > thead > tr > th {
  background-color: #4d4d4d !important;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.print-container table > thead > tr > th:first-child {
  padding-left: 10px;
}
.print-container table > thead > tr > th:last-child {
  padding-right: 10px;
}

.print-container table > tbody > tr > td {
  border-bottom: #e5e5e5 solid 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.print-container table > tbody > tr > td:first-child {
  border-bottom: #e5e5e5 solid 1px;
  padding-left: 10px;
}
.print-container table > tbody > tr > td:last-child {
  border-bottom: #e5e5e5 solid 1px;
  padding-right: 10px;
}
