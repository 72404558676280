.ileggelse-filter-container {
  width: 100%;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  padding: 10px 50px 20px 50px;
}

.ileggelse-filter-container h1 {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4d4d4d;
  margin: 0px;
  padding-bottom: 20px;
}

.ileggelse-filter-flexbox-item > div {
  flex-basis: 12.5%;
}

.ileggelse-filter-flexbox-item > div:last-child {
  flex-basis: 12.5%;
}
