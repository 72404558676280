.user-icon-container {
  display: inline-block;
  vertical-align: middle;
}

.user-icon-container-block {
  width: 100%;
}

.user-icon {
  display: flex;
  align-items: center;
}

.user-icon > span {
  margin-left: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-icon-small > img {
  width: 22px;
  height: 22px;
}

.user-icon-medium > img {
  width: 30px;
  height: 30px;
}

.user-icon-large > img {
  width: 33px;
  height: 33px;
}
