.pagination-control {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  /* opacity: 0.54; */
}

.pagination-control .pc-page-number {
  text-align: right;
  display: inline-block;
  min-width: 100px;
}

.pagination-control .pc-space-small {
  margin-left: 28px;
}

.pagination-control .pc-space-large {
  margin-left: 40px;
}

.pagination-control select {
  border: none;
}

.pagination-control button {
  background: transparent;
  border: none;
}

/********************* bolded style **************/
.pagination-control-container {
  border-bottom: 1px solid #f3f3f3;
  /* width: 100%; */
  height: 40px;
}
#boldedStyle {
  font-size: 16px;
  font-weight: normal;
  color: #4d4d4d;
}

#boldedStyle .pc-page-number {
  text-align: right;
  font-weight: bold;
  font-size: 16px;

  display: inline-block;
  min-width: 100px;
}

#boldedStyle .pc-space-small {
  margin-left: 28px;
}

#boldedStyle .pc-space-small > img,
#boldedStyle .pc-space-large > img {
  width: 15.2px;
  height: 20.8px;
}
#boldedStyle .pc-space-large {
  margin-left: 40px;
}

#boldedStyle select {
  border: none;
  font-weight: bold;
}

#boldedStyle button {
  background: transparent;
  border: none;
  font-weight: bold;
}
