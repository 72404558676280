div.historikk-list-row:nth-child(2n) {
  background-color: #f1f1f1;
}

div.historikk-list-row {
  padding: 10px 17px;
}

div.historikk-list-row div.historikk-headers {
  font-weight: bold;
  font-size: 14px;
}

div.historikk-list-row div.historikk-user-name {
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
}

div.historikk-list-row div.historikk-tidspunkt {
  display: inline-block;
  padding-left: 5px;
  vertical-align: top;
}

div.historikk-list-row div.historikk-field-row {
  font-size: 12px;
}
