.grid-table-wrapper {
  width: 100%;
  display: block;
  overflow-y: visible;
  white-space: nowrap;
  overflow-x: auto;
}

table.grid-table {
  font-size: 12px;
  width: 100%;
  table-layout: fixed;
  white-space: normal;
}

.grid-table tr:nth-child(odd) {
  background-color: #fff;
}

.grid-table tr:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.grid-table td {
  padding-top: 15px;
  padding-bottom: 19px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.grid-table td:first-child {
  padding-left: 50px;
}

.grid-table td:last-child {
  padding-right: 50px;
}

.grid-table td:not(:first-child) {
  padding-left: 10px;
}

.grid-table-name {
  font-weight: bold;
}

.grid-table-value {
  font-size: 16px;
}

/* One colored grid-table */
table.one-colored-grid-table {
  font-size: 12px;
  width: 100%;
  background-color: #fff;
}

.one-colored-grid-table td {
  padding-top: 15px;
  padding-bottom: 19px;
}

.one-colored-grid-table td:first-child {
  padding-left: 50px;
}

.one-colored-grid-table td:last-child {
  padding-right: 50px;
}

.one-colored-grid-table td:not(:first-child) {
  padding-left: 10px;
}

@media print {
  .grid-table td:first-child {
    padding-left: 0;
  }

  .one-colored-grid-table td:first-child {
    padding-left: 0;
  }
}
