.alert {
  position: fixed;
  z-index: 2000000;
  text-align: center;
  width: 100%;
  height: 70px;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 10;
}
