ul.navigation-bar li a.active {
  color: #fff;
  background-color: #2b2b2b;
}

ul.navigation-bar li a.focus {
  color: #fff;
  background-color: yellowgreen;
}

ul.navigation-bar {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background-color: #4d4d4d;
}

ul.navigation-bar li {
  min-height: 60px;
}

ul.navigation-bar li a {
  display: block;
  color: #d8d8d8;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  padding: 21px 20px;
  min-height: 60px;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  ul.navigation-bar {
    margin: 0;
    padding-left: 50px;
  }

  ul.navigation-bar li {
    float: left;
  }
}

@media screen and (max-width: 767px) {
  ul.navigation-bar li {
    min-height: 40px;
  }

  ul.navigation-bar li a {
    text-align: left;
    min-height: 40px;
  }
}
