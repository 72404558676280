.pageanonymous-header {
  max-width: 1080px;
  margin: auto;
  padding: 0px 60px;
  background-color: #ffffff;  
}
/* Responsiv pageanonymous-header */
@media (max-width: 1120px) {
  .pageanonymous-header {   
    margin-left: 20px;
    margin-right: 20px;    
  }
}
@media (max-width: 335px) {
  .pageanonymous-header {   
    min-width: 335px;
    margin:0px;    
  }
}

.pageanonymous-header > h1 { 
  margin: 0px;
  padding-top: 113px;
  padding-bottom: 20px;  
  font-family: Lato;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.1;
  text-align: center;
  color: #4d4c4d;
}
@media (max-width: 800px) {
  .pageanonymous-header > h1 {   
    padding-top: 80px;  
  }
}
@media (max-width: 335px) {
  .pageanonymous-header > h1 {   
    padding-top: 50px;  
  }
}
.pageanonymous-body {
  max-width: 1080px;
  margin: auto;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 300px;
  background-color: #ffffff;
}
/* Responsiv pageanonymous-body */
@media (max-width: 1120px) {
  .pageanonymous-body {   
    margin-left: 20px;
    margin-right: 20px;    
  }
}
@media (max-width: 645px) {
  .pageanonymous-body {   
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 50px;
  }
}
@media (max-width: 450px) {
  .pageanonymous-body {   
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
  }
}
@media (max-width: 335px) {
  .pageanonymous-body {   
    min-width: 335px;
    margin:0px;
  }
}