.bym-table.bym-ettersendelser-til-godkjenning-table.table-striped
  tbody
  > tr
  > td:first-child,
.bym-table.bym-ettersendelser-til-godkjenning-table.table-striped
  thead
  > tr
  > th:first-child {
  padding: 20px 10px 20px 20px;
  width: 16px;
}
