.SearchFormDiv {
  width: 100%;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  padding: 10px 50px 20px 50px;
}

.SearchFilterOverskrift {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 10px;
}
.resutlTableDiv {
  padding: 10px 50px 20px 50px;
  margin-top: 5px;
}
.resutlTableDiv table {
  margin-top: 10px;
}
/**************************tabs *****************/
#InnsynnIleggelseTabs {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

#InnsynnIleggelseTabs .nav {
  padding-left: 50px;
}

#InnsynnIleggelseTabs .tab-content {
  /* padding-left: 25px;
  padding-right: 20px; */
  width: 100%;
}

#InnsynnIleggelseTabs ul.nav-tabs li {
  font-size: 19px;
  font-weight: bold;
  width: 180px;
  text-align: center;
}

#InnsynnIleggelseTabs .historikk-list-row {
  margin: 10px 40px;
}

.SearchInnsynForm .col-md-2 {
  min-width: 150px;
}

/********************************/

.understellsnummer-filter-flexbox-container > div {
  max-width: 230px;
  min-width: 230px;
  padding-right: 30px;
  flex-shrink: 1;
}

.innsyn-motorvogn-filter-flexbox-item > div {
  flex-basis: 16.66%;
}

.innsyn-ileggelsesok-filter-flexbox-item > div {
  flex-basis: 20%;
}

.innsyn-miljogebyrsok-filter-flexbox-item > div {
  flex-basis: 20%;
}

/********************************/
