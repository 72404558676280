@import url("https://ukeweb-styleguide-cdn.s3.eu-central-1.amazonaws.com/0.99.93/osg.css"); /* Version 0.99.93 og ikke høgre då denne ikke har nav arrows for buttons */

/* ================ BODY ================ */
body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: white;
  color: #4d4d4d;
  -webkit-print-color-adjust: exact;
}
/* ================ END BODY ================ */

/* ================ HEADING ================ */
h1 {
  margin-top: 20px;
  padding-bottom: 10px;
  font-size: 36px;
  padding: 0;
}

h2 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}

h3 {
  font-size: 24px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 10px;
}
/* ================ END HEADING ================ */

a {
  color: #337ab7;
}

a:hover,
a:focus {
  color: #23527c;
}

/* ================ SPINNER ================ */
.spinner-bym-small {
  height: 16px;
  width: auto;
  display: inline-block;
  color: green;
}
/* ================ END SPINNER ================ */

/* ================ LABEL ================ */
label {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: #4d4d4d;
}

.fake-label {
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.label-13px-bold {
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}
/* ================ END LABEL ================ */

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding: 0;
}

/* ================ INPUT ================ */
/* input number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #a9a9a9 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9 !important;
}

::placeholder {
  color: #a9a9a9 !important;
}

.form-control {
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
}
/* ================ END INPUT ================ */

/* ================ BUTTON ================ */
.btn {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2;
  line-height: 1;
  color: #4d4d4d;
  border-radius: 6px;
}

.btn:focus,
.btn.focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  box-shadow: none;
}

.btn .badge {
  text-shadow: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0 none;
}
/* ================ BUTTON ================ */
/* ================ BUTTON LINK ================ */
.btn-link {
  color: #0071bb;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-decoration: underline;
}

.btn-link:active,
.btn-link:focus,
.btn-link:active:focus {
  color: #005187;
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-decoration: none;
  text-decoration: underline;
}

.btn-link:hover {
  text-decoration: underline;
  color: #005187;
  background-color: transparent;
}

.btn-primary.btn-link {
  color: #0071bb;
  background-color: transparent;
  border: none;
  text-decoration: none;
  text-decoration: underline;
}

.btn-primary.btn-link:hover {
  text-decoration: underline;
  color: #005187;
  background-color: transparent;
}

.btn-primary.btn-link:active,
.btn-primary.btn-link:focus,
.btn-primary.btn-link:active:focus {
  color: #005187;
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-decoration: none;
  text-decoration: underline;
}

.btn-primary.btn-link.btn-makuler {
  color: #a94442;
}

.btn-primary.btn-link:hover {
  text-decoration: underline;
  color: #005187;
  background-color: transparent;
}

/* .btn-bym-sizes */
.btn-bym-small {
  height: auto;
  width: auto;
  line-height: 1;
  padding: 5px 10px;
}

.btn-bym-medium {
  height: auto;
  width: auto;
  line-height: 1;
  padding: 10px 20px;
}

.btn-bym-large {
  height: auto;
  width: auto;
  line-height: 1;
  padding: 10px 40px;
}

.btn-primary.btn-bym-standard {
  font-weight: 700;
  color: #4d4d4d;
  height: 40px;
  border-radius: 6px;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
}

.btn-bym-standard {
  font-weight: 700;
  color: #4d4d4d;
  height: 40px;
  border-radius: 6px;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
}

.btn-bym-standard svg {
  margin-right: 5px;
}

.btn-bym-standard:hover {
  background-color: #e9e9e9;
  border: solid 1px #d8d8d8;
}

.btn-bym-standard:focus {
  background-color: #d4d4d4;
  border: solid 1px #c4c4c4;
}

.btn-bym-standard:active,
.btn-bym-standard:active:focus {
  background-color: #d4d4d4;
  border: solid 1px #c4c4c4;
}

.btn-bym-standard.disabled,
.btn-bym-standard[disabled],
fieldset[disabled] .btn-bym-standard,
.btn-bym-standard.disabled:hover,
.btn-bym-standard[disabled]:hover,
fieldset[disabled] .btn-bym-standard:hover,
.btn-bym-standard.disabled:focus,
.btn-bym-standard[disabled]:focus,
fieldset[disabled] .btn-bym-standard:focus,
.btn-bym-standard.disabled.focus,
.btn-bym-standard[disabled].focus,
fieldset[disabled] .btn-bym-standard.focus,
.btn-bym-standard.disabled:active,
.btn-bym-standard[disabled]:active,
fieldset[disabled] .btn-bym-standard:active,
.btn-bym-standard.disabled.active,
.btn-bym-standard[disabled].active,
fieldset[disabled] .btn-bym-standard.active {
  background-color: #e0e0e0;
}

/* .btn-bym-action */
.btn-bym-action.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle:hover {
  /* background-color: #cde3f2 !important;
  color: #333 !important;
  border: 1px solid #cee4f2 !important; */
  background-color: #cde3f2;
  color: #333;
  border: 1px solid #cee4f2;
}

.btn-primary {
  /* background-color: #cde3f2 !important;
  color: #333 !important;
  border: 1px solid #cee4f2 !important; */
  background-color: #cde3f2;
  color: #4d4d4d;
  border: 1px solid #cee4f2;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #333 !important;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-bym-action.disabled,
.btn-bym-action[disabled],
fieldset[disabled] .btn-bym-action,
.btn-bym-action.disabled:hover,
.btn-bym-action[disabled]:hover,
fieldset[disabled] .btn-bym-action:hover,
.btn-bym-action.disabled:focus,
.btn-bym-action[disabled]:focus,
fieldset[disabled] .btn-bym-action:focus,
.btn-bym-action.disabled.focus,
.btn-bym-action[disabled].focus,
fieldset[disabled] .btn-bym-action.focus,
.btn-bym-action.disabled:active,
.btn-bym-action[disabled]:active,
fieldset[disabled] .btn-bym-action:active,
.btn-bym-action.disabled.active,
.btn-bym-action[disabled].active,
fieldset[disabled] .btn-bym-action.active {
  color: #333;
}

.btn-bym-action {
  height: 40px;
  border-radius: 6px;
  background-color: #f2f8fc;
  border: solid 1px #cee4f2;
}

.btn-bym-action:hover {
  background-color: #e3eff8;
  color: #333;
  border: solid 1px #cee4f2;
}

.btn-bym-action:focus {
  background-color: #cde3f2;
  color: #333;
  border: solid 1px #cee4f2;
  box-shadow: none;
}

.btn-bym-action.active,
.btn-bym-action:active:focus {
  background-color: #cde3f2 !important;
  border: solid 1px #cee4f2;
}

.btn-bym-action:active:hover,
.btn-bym-action.active:hover,
.open > .dropdown-toggle.btn-bym-action:hover,
.btn-bym-action:active:focus,
.btn-bym-action.active:focus,
.open > .dropdown-toggle.btn-bym-action:focus,
.btn-bym-action:active.focus,
.btn-bym-action.active.focus,
.open > .dropdown-toggle.btn-bym-action.focus {
  color: #4d4d4d;
  background-color: #cde3f2;
  border-color: #cee4f2;
}

.btn-bym-action.disabled,
.btn-bym-action[disabled],
fieldset[disabled] .btn-bym-action,
.btn-bym-action.disabled:hover,
.btn-bym-action[disabled]:hover,
fieldset[disabled] .btn-bym-action:hover,
.btn-bym-action.disabled:focus,
.btn-bym-action[disabled]:focus,
fieldset[disabled] .btn-bym-action:focus,
.btn-bym-action.disabled.focus,
.btn-bym-action[disabled].focus,
fieldset[disabled] .btn-bym-action.focus,
.btn-bym-action.disabled:active,
.btn-bym-action[disabled]:active,
fieldset[disabled] .btn-bym-action:active,
.btn-bym-action.disabled.active,
.btn-bym-action[disabled].active,
fieldset[disabled] .btn-bym-action.active {
  background-color: #f2f8fc;
  border-color: #cee4f2;
}

/* .btn-bym-positive */
.btn-primary.btn-bym-positive {
  height: 40px;
  color: #4d4d4d;
  background-color: #daf6ed;
  border-color: #b7ecdc;
}

.btn-primary.btn-bym-positive:hover {
  background-color: #c2efe1;
  border-color: #b8ecdc;
}

.btn-primary.btn-bym-positive:focus {
  background-color: #90e2c9;
  border-color: #71d9b8;
}

.btn-primary.btn-bym-positive:active:focus,
.btn-primary.btn-bym-positive.active:focus,
.btn-primary.btn-bym-positive:active {
  background-color: #daf6ed;
  border-color: #b7ecdc;
}

.btn-bym-positive.disabled,
.btn-bym-positive[disabled],
fieldset[disabled] .btn-bym-positive,
.btn-bym-positive.disabled:hover,
.btn-bym-positive[disabled]:hover,
fieldset[disabled] .btn-bym-positive:hover,
.btn-bym-positive.disabled:focus,
.btn-bym-positive[disabled]:focus,
fieldset[disabled] .btn-bym-positive:focus,
.btn-bym-positive.disabled.focus,
.btn-bym-positive[disabled].focus,
fieldset[disabled] .btn-bym-positive.focus,
.btn-bym-positive.disabled:active,
.btn-bym-positive[disabled]:active,
fieldset[disabled] .btn-bym-positive:active,
.btn-bym-positive.disabled.active,
.btn-bym-positive[disabled].active,
fieldset[disabled] .btn-bym-positive.active {
  background-color: #daf6ed;
}

/* .btn-bym-negative */
.btn-bym-negative {
  color: #4d4d4d;
  background-color: #fee0dc;
  border-color: #fdc2bd;
}

.btn-bym-negative:hover {
  color: #4d4d4d;
  background-color: #fdc0ba;
  border-color: #fca79e;
}

.btn-bym-negative:focus {
  color: #4d4d4d;
  background-color: #fca198;
  border-color: #fc8479;
}

.btn-bym-negative:focus:active,
.btn-bym-negative:active,
.btn-bym-negative.active {
  color: #4d4d4d;
  background-color: #fee0dc;
  border-color: #fdc2bd;
}

.btn-bym-negative.disabled,
.btn-bym-negative[disabled],
fieldset[disabled] .btn-bym-negative,
.btn-bym-negative.disabled:hover,
.btn-bym-negative[disabled]:hover,
fieldset[disabled] .btn-bym-negative:hover,
.btn-bym-negative.disabled:focus,
.btn-bym-negative[disabled]:focus,
fieldset[disabled] .btn-bym-negative:focus,
.btn-bym-negative.disabled.focus,
.btn-bym-negative[disabled].focus,
fieldset[disabled] .btn-bym-negative.focus,
.btn-bym-negative.disabled:active,
.btn-bym-negative[disabled]:active,
fieldset[disabled] .btn-bym-negative:active,
.btn-bym-negative.disabled.active,
.btn-bym-negative[disabled].active,
fieldset[disabled] .btn-bym-negative.active {
  background-color: #fee0dc;
  border-color: #fdc2bd;
}
/* ================ END BUTTON ================ */

/* ================ FILTER-FORM ================ */
.filter-form {
  width: 100%;
  background-color: #f3f3f3;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  padding: 10px 50px 20px 50px;
}

.filter-container {
  width: 100%;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  padding: 10px 50px 20px 50px;
}

.filter-form h1 {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4d4d4d;
  margin: 0px;
  padding-bottom: 20px;
}

.filter-container h1 {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4d4d4d;
  margin: 0px;
  padding-bottom: 20px;
}

.filter-flexbox-container {
  display: flex;
  flex-wrap: wrap;
}

.filter-flexbox-container > div {
  max-width: 230px;
  min-width: 160px;
  padding-right: 30px;
  flex-shrink: 1;
}
/* ================ END FILTER-FORM ================ */

/* ================ BADGE ================ */
.badge-bym-gray {
  font-size: 12px;
  border-radius: 11px;
  background-color: #f1f7fb;
  color: #4d4d4d;
  border: solid 1px #00548c;
  font-family: Lato;
  padding: 3px 7px;
  display: inline-block;
}

.badge-bym-red {
  border-radius: 15px;
  background-color: #a94442;
  color: #fff;
  border: solid 1px #a94442;
  font-family: Lato;
  padding: 3px 7px;
}
/* ================ END BADGE ================ */

.link-makulert {
  color: #fff;
}
.link-makulert:hover {
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #fff;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .only-print,
  .only-print * {
    display: block !important;
  }
}

.only-print,
.only-print * {
  display: none;
}

/* Styles, why not just use styles ?!?! */
.left-20px {
  margin-left: 20px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #a94442;
  background-image: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
  border-color: #a94442;
}

.help-block {
  color: #a94442;
}

.error {
  color: #a94442;
}

.red,
.red:hover {
  color: #a94442;
}

.btn.btn-primary.red {
  color: #a94442;
}

.btn.btn-primary.removeButtonLink:focus,
.btn.btn-primary.removeButtonLink:active,
.btn.btn-primary.removeButtonLink:hover {
  color: #a94442;
}

.disabled {
  border-radius: 6px;
  background-color: #f3f3f3;
  height: 37px;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
}

.underline {
  text-decoration: underline;
}

.tableContainer {
  padding: 0px 50px;
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 20px;
}

.list-pagination-wrapper {
  padding: 0px 50px;
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.list-pagination-wrapper {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4d4d4d;
  font-weight: bold;
}

.main-container {
  padding: 10px 50px 50px 50px;
}

.visuallyhidden-no-space {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.visuallyhidden {
  opacity: 0;
}

.print-out-table-row-makulert {
  background-color: #f2f2f2 !important;
}

.border-bottom {
  border-bottom: 1px solid #cccccc;
  width: 100%;
  line-height: 30px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.validation-error {
  color: #a94442;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.row {
  margin-right: 0px;
}

.list-page-total {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4d4d4d;
  font-weight: bold;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 0px;
}
