.searchfield {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #9b9b9b;
  padding: 0px 10px;
}

.text-field-search-icon {
  position: relative;
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  z-index: 1;
}

.fa-times-circle {
  float: right;
  z-index: 2;
  margin-top: 6px;
  width: 10px;
}

.searchfield:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.searchfield:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.searchfield:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.searchfield:focus:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
