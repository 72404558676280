.rapportSearch {
  width: 100%;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  padding: 10px 50px 20px 50px;
}

@media (min-width: 768px) {
  .rapport-buttons-container {
    padding: 10px 50px 0px 50px;
    margin-top: 5px;
  }

  .rapport-table {
    padding: 0px 50px 20px 50px;
  }
}

@media (max-width: 767px) {
  .rapport-buttons-container {
    padding: 10px 20px 0px 20px;
    margin-top: 5px;
  }

  .rapport-table {
    padding: 0px 0px 0px 0px;
  }
}

.rapport-table table {
  margin-top: 10px;
}

.rapport-table table thead {
  height: 56px;
  background-color: #f3f3f3;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  box-shadow: inset 0 -1px 0 0 var(--white);
}

.rapport-table table tr#table-sum-row {
  font-weight: bold;
}

.rapport-table table thead > tr > td:first-child {
  padding: 20px 10px 20px 20px;
}

.rapport-table table thead > tr > td {
  padding: 20px 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.rapport-table table tbody > tr > td:first-child {
  padding: 20px 10px 20px 20px;
}

.rapport-table table tbody > tr > td {
  padding: 20px 10px;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.ventilstilling-filter-flexbox > div {
  flex-basis: 33.33%;
}

.piggdekkontroll-filter-flexbox > div {
  flex-basis: 33.33%;
}

.klager-filter-flexbox > div {
  flex-basis: 25%;
}

.juridisk-saksbehandler-filter-flexbox > div {
  flex-basis: 33.33%;
}

.skiftlogg-filter-flexbox > div {
  flex-basis: 33.33%;
}

.skiftlogg-filter-flexbox-for-bypatruljen > div {
  flex-basis: 25%;
}

.table-striped > tbody > tr.RapportListTotalRow td {
  font-size: 17px;
  font-weight: bold;
}
