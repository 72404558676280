.radiobuttongroup input[type="radio"] {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.radiobuttongroup span {
  position: absolute;
  margin-top: 4px;
  margin-left: 5px;
}

/*
.radiobuttongroup input[type="radio"] {
   display: none;
  position: absolute; 
  height: 20px;
  width: 20px;
}
.radiobuttongroup input[type="radio"] + span:before {
  height: 20px;
  width: 20px;
  margin: -2px 10px 0 0;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #777;
  cursor: pointer;
  border-radius: 50%;
}
.radiobuttongroup input[type="radio"]:checked + span:before {
  background-color: #777;
} 
*/
