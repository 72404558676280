.transparent-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* 50% transparent black */
  z-index: 9999;
  overflow-y: scroll; /* enable scrolling on the transparent div only */
}

.lightbox-image-container {
  /* align the image horizontally */
  /* text-align: center;  */
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lightbox-image-container img {
  /* set the image display to block */
  display: block;
  /* center the image horizontally */
  max-height: 700px;
}

.carousel-navigation-button {
  box-sizing: content-box;
  color: #2a2859;
  background-color: #f9c66b;
  width: 1.5rem;
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 0.5rem;
  border: 2px solid #f9c66b;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-navigation-button:hover {
  color: #fff !important;
  background-color: #1f42aa !important;
  border: 2px solid #1f42aa !important;
}

.carousel-navigation-button-icon {
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
  font-size: 1.375rem;
}

@media only screen and (max-width: 500px) {
  .carousel-navigation-button {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .carousel-navigation-button-icon {
    line-height: 0.75rem;
    display: inline-block;
    vertical-align: top;
    font-size: 0.75rem;
  }
}
