.multi-checkbox-select-dropdown-container {
  margin-bottom: 5px;
}

.multi-checkbox-select-dropdown {
  display: block;
  height: 40px;
}

.multi-checkbox-select-dropdown > button {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-color: #ccc;
}

.multi-checkbox-select-dropdown > button:focus,
.multi-checkbox-select-dropdown > button:focus:hover,
.multi-checkbox-select-dropdown > button:active {
  background-color: #fff;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
}

.multi-checkbox-select-dropdown > button:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #e6e6e6;
  color: #555555;
  border-color: #adadad;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle:hover {
  background-color: #fff;
  color: #a9a9a9;
}

.multi-checkbox-select-dropdown .dropdown-toggle::after {
  margin-top: 7px;
}

.multi-checkbox-select-dropdown #searchCleanBtn {
  float: right;
  margin-top: -40px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin-right: 12px;
  border-style: none;
  background-color: white;
}

input::-ms-clear {
  display: none;
}

.multi-checkbox-select-dropdown > button {
  height: 40px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
}

@-moz-document url-prefix() {
  .multi-checkbox-select-dropdown > button > span.caret {
    float: right;
    color: #555555;
  }
}

.multi-checkbox-options-toggle-placeholder-velg {
  color: #a9a9a9 !important;
}

.multi-checkbox-select-dropdown-ul {
  max-height: 350px;
  overflow-y: auto;
  z-index: 2000;
  padding: 5px;
  width: 100%;
  margin-top: 0px;
}

.multi-checkbox-select-dropdown-ul > li > div {
  color: rgb(74, 74, 74);
}

.multi-checkbox-select-dropdown-ul > li > div > label > span {
  font-size: 16px;
  padding-left: 15px;
  margin-top: 0px;
  cursor: pointer;
}

.multi-checkbox-select-dropdown-ul > li:hover {
  background-color: #f2f9fc;
  cursor: pointer;
  display: block;
}

ul.multi-checkbox-select-dropdown-ul > li > div:hover {
  cursor: pointer;
}

.multi-checkbox-select-dropdown-ul .btnClose {
  margin: 10px 20px 15px 15px;
  padding: 5px;
  height: 40px;
}

.multi-checkbox-select-dropdown button.dropdown-toggle,
.multi-checkbox-select-dropdown-container
  .open
  > .dropdown-toggle.btn-default:focus,
.multi-checkbox-select-dropdown-container
  .open
  > .dropdown-toggle.btn-default:hover,
.multi-checkbox-select-dropdown-container .open > .dropdown-toggle.btn-default,
.multi-checkbox-select-dropdown button.dropdown-toggle:focus {
  text-align: left;
  font-weight: normal;
  height: 40px;
  width: 100%;
}

.has-error .multi-checkbox-select-dropdown > button {
  border-color: #a94442;
}
