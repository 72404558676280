.status-page-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 80em;
  margin: 0 auto 16rem auto;
  gap: 1em;
}

.status-page-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
