/* Editable Field Css*/

.editable-field-form {
  margin-bottom: 0;
  width: 100%;
  height: 40px;
}

.editable-field-textarea {
  resize: none;
  font-size: 14px;
  line-height: 30px;
  color: inherit;
  height: 40px;
  overflow-y: hidden !important;
  /* font-weight: normal;*/
}

.editable-field-select {
  font-size: 16px;
  line-height: 30px;
  color: inherit;
  height: 40px;
  overflow-y: hidden !important;
  /* "Hack" to remove expand-arrow in select box */
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* "Hack" to remove expand-arrow in select box */
.editable-field-select::-ms-expand {
  display: none;
}

.editable-field-button-save {
  color: #7ed321;
}

.editable-field-button-cancel {
  color: #d0021b;
}

.editable-field-form {
  position: relative;
}

/*.editable-field-buttons {
vertical-align: bottom;
}*/

/*in place editing*/

.editable-field .form-control {
  border-color: transparent;
  /*background-color: white;*/
  background-color: transparent;
  box-shadow: none;
  transition: none;
}

/*select {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border: none;
background: none;
}*/

.editable-field .editable-field-span {
  opacity: 0;
  border-style: none;
}

.editable-field:hover .editable-field-span {
  opacity: 1;
  border: 1px dashed #ccc;
  border-left: none;
  border-radius: 6px;
  cursor: pointer;
  padding-right: 12px;
  padding-left: 12px;
}

.editable-field:hover .form-control {
  border-color: #ccc;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-style: dashed;
}
.input-group-addon .dropdownlistEditable {
  height: 40px;
  padding-top: 2px;
}
#dotted {
  border: 1px gray dashed;
  border-radius: 5px;
}
#divider {
  border-right: 1px solid gray;
  padding: 0;
}
.input-group-addon {
  background-color: white;
  cursor: pointer;
}
.editable-field-buttons button {
  background-color: white;
}

/* Common Styles */
.editable-field-jira {
  position: relative;
}

.editable-field-jira-buttons {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 100;
}

.editable-field-jira .form-control {
  font-size: 16px;
  line-height: 30px;
  color: inherit;
  height: 40px;
  overflow-y: hidden !important;
}

.editable-field-jira-buttons button {
  background-color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.editable-field-jira-buttons .save {
  color: #7ed321;
}

.editable-field-jira-buttons .cancel {
  color: #d0021b;
}

.disabled,
.disabled:focus,
.disabled:hover,
.disabled > button,
.disabled > button:hover .disabled > button:focus {
  border-radius: 6px;
  background-color: #f3f3f3;
}
