@media screen and (min-width: 700px) {
  h1.grunndata-page {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d4d;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 50%;
  }
}

@media screen and (max-width: 699px) {
  h1.grunndata-page {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d4d;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .grunndata-fake-nav {
    height: 60px;
    width: 100%;
    background-color: #4d4d4d;
    padding-top: 13px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) {
  .grunndata-fake-nav {
    height: 60px;
    width: 100%;
    padding-top: 13px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: #4d4d4d;
  }
}

ul.dropdown-menu > li > a {
  color: #4d4d4d;
  font-size: 16px;
  height: 40px;
  padding-top: 10px;
}

#velg-grunndata {
  width: 168px;
  height: 40px;
  border-radius: 6px;
  color: #4d4d4d;
  background-color: #f3f3f3;
  border: solid 1px #e9e9e9;
  --webkit-box-shadow: none;
  box-shadow: none;
}
#velg-grunndata:hover,
#velg-grunndata:activ,
#velg-grunndata:focus {
  color: #4d4d4d;
  background-color: #f3f3f3;
  --webkit-box-shadow: none;
  box-shadow: none;
}

.bybetjenterModal {
  width: 50%;
}
