.confirmationModal .btn-link,
.confirmationModal .btn-link:hover,
.confirmationModal .btn-link:focus,
.confirmationModal .btn-link:active {
  color: #a94442;
}

.confirmationModal .modal-title {
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
}

.confirmationModal .modal-body {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #4d4d4d;
}

.confirmationModal .modal-footer button {
  float: left;
}

.confirmationModal .modal-footer button.btn-default {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #0071bb;
  border: none;
  height: 40px;
  background-color: white;
}

.confirmationModal .modal-footer button.btn-danger,
button.btn-danger:hover {
  border-radius: 6px;
  height: 40px;
  background-color: #f2f8fc;
  border: solid 1px #cee4f2;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  color: #4a4a4a;
  min-width: 100px;
}
